<template>
  <div
    class="price-switch section-plans__mb"
    :class="{'price-switch--rtl': isArabic}"
  >
    <span class="price-switch__text"
          :class="{'price-switch__text_active': myModelValue === false}">
      {{ $t('tariffs_month') }}
    </span>
    <UiButtonSwitch
      v-model="myModelValue"
      :color="color"
    />
    <span class="price-switch__text"
          :class="{'price-switch__text_active': myModelValue === true}">
      {{ $t('tariffs_year') }}
    </span>
    <span class="price-switch__sale" v-if="props.sale"
          :style="{borderColor: color, color: color}">
      {{ $t('tariffs_sale') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import {ref, watch, computed} from 'vue';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';

const props = withDefaults(
  defineProps<{
    modelValue: {
      type: Boolean,
      required: true,
    },
    color: String,
    sale: Boolean,
  }>(),
  {
    sale: true,
  },
);

const myModelValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);

function updateValue() {
  emit('update:modelValue', myModelValue.value);
}

watch(myModelValue, () => {
  updateValue();
});

// Проверка на наличие 'ar' в URL
const route = useRoute();
const localeFromPath = getLocaleFromPath(route.fullPath);
const isArabic = computed(() => localeFromPath === 'ar');
</script>

<style scoped>
.price-switch {
  display: flex;
  align-items: center;
  gap: 16px;
}

.price-switch--rtl {
  direction: rtl;
  flex-direction: row-reverse;
}

.price-switch__sale {
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid;
  border-radius: 8px;
  border-color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}

.price-switch__text {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #839AAF;
}

.price-switch__text_active {
  color: #3D424A;
}

.section-plans__mb {
  margin-bottom: 48px;
}
</style>
