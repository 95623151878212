<template>
  <label class="switch">
    <input @click="updateValue" type="checkbox" :checked="modelValue">
    <span class="slider round" :style="sliderStyle"/>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  color: {
    type: String,
    default: 'red'
  }
});

const emit = defineEmits(['update:modelValue']);

function updateValue() {
  emit('update:modelValue', !props.modelValue);
}

const sliderStyle = computed(() => ({
  backgroundColor: props.modelValue ? props.color : '#ccc'
}));
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
