<template>
  <div class="toggle-container"
       :style="{borderRadius: containerRadius, maxWidth: `${toggleWidth}px}`}"
       ref="toggle">
    <div class="toggle-container__item"
         :class="{ 'toggle-container__item_active': modelValue === 0 }"
         @click="updateValue(0)">
      <slot name="first">
        {{ $t('home_page_forEveryone') }}
      </slot>
    </div>

    <div class="toggle-container__item"
         :class="{ 'toggle-container__item_active': modelValue === 1 }"
         @click="updateValue(1)">
      <slot name="second">
        {{ $t('home_page_forBusiness') }}
      </slot>
    </div>

    <div v-if="!isArabic" class="toggle-container__indicator"
         :style="{ left: (modelValue * getToggleWidth()/2) + 4 + 'px',
            borderRadius: toggleRadius, backgroundColor: toggleColor}"/>
    <div v-else class="toggle-container__indicator"
         :style="{ right: (modelValue * getToggleWidth()/2) + 4 + 'px' }"/>

  </div>
</template>

<script setup>
import {useDirectionStore} from '~/store/direction.store';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';

const direction = useDirectionStore();
const right = computed(() => direction.isRight);

/* Settings */
const items = ['home_page_forEveryone', 'home_page_forBusiness'];
const toggleWidth = 408;
const toggle = ref(null);

const route = useRoute();
const localeFromPath = getLocaleFromPath(route.fullPath);
const isArabic = computed(() => localeFromPath === 'ar');

const getToggleWidth = () => {
  if (toggle.value) return toggle.value.offsetWidth;
  return toggleWidth;
}

const computedStyle = computed(() => {
  return {left: (this.modelValue * this.getToggleWidth() / 2) + 4 + 'px'};
})

/* Props and v-model */
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  toggleRadius: String,
  toggleColor: String,
  containerRadius: String
});

const emit = defineEmits(['update:modelValue']);

function updateValue(value) {
  emit('update:modelValue', value);
}
</script>

<style scoped lang="scss">
.toggle-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 12px;
  background: #F6F8FD;
}

.toggle-container__item {
  cursor: pointer;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  z-index: 1;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;

  @media (max-width: $md) {
    padding: 12px 16px 12px 16px;
  }
}

.toggle-container__item_active {
  color: white;
  transition: 0.3s;
}

.toggle-container__indicator {
  position: absolute;
  z-index: 0;
  top: 4px;
  left: 8px;
  width: calc(50% - 8px);
  background-color: #2491E7;
  border-radius: 8px;
  height: calc(100% - 8px);
  transition: 0.3s ease-in-out;
}
</style>
